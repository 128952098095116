export const categoriesData =[
    'Anime',
    'Gaming',
    'Hollywood Movies',
    'Marvel',
    'Music',
    'Others',
    'Personalities',
    'Pop Art',
    'Sports',
    'Travel and Adventure',
    'Web Series'
  ]
  
  export const subCategoryData ={
    'Anime':['Death Note','Naruto','One Piece','Pokemon','Dragon Ball Z', 'Deamon Slayer', 'Jujutsu Kaisen', 'Others'],
    'Gaming':['Call of Duty', 'Games'],
    'Hollywood Movies':['Batman','Harry Potter','John Wick','Joker','King Kong','Madmax','Minion','Sherlock','Superman'],
    'Marvel':['Captain America','Doctor Strange','Hulk','Iron Man','Spiderman','Thor','Wolverine'],
    'Music':['Artists', 'Others'],
    'Others':['Air Kicks','Classic Cars','Coder','Devotional','Floral','Game Explorer','Gym','Minimal','Pets','Pride','Quotes','Samurai','Shades of Chai and Coffee','Space','Bollywood Movies','Others'],
    'Personalities':['Narendra Modi','Rajnikanth'],
    'Pop Art':['India','Retro Bikes','Retro Girl'],
    'Sports':['F1','Dhoni', 'Virat Kohli','Football'],
    'Travel and Adventure':['Destinations','Wanderlust'],
    'Web Series':['Peaky Blinders','Breaking Bad','Friends','Stranger Things', 'Others']
  }

  export const shopCategories = {
    "Shop All": [...categoriesData],
    Collections:[],
    Posters:[...categoriesData],
    'Block Kits': [],
  }
  
  export const formatDate = ()=> {
      const date = new Date();
      const options = {
        year: '2-digit',
        month: 'short',
        day: 'numeric'
      };
      const dateStr = date.toLocaleDateString('en-US', options);
      
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const timeStr = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    
      return `${dateStr} | ${timeStr}`;
    }
    