import { keyframes, styled } from "styled-components";

export const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const QuantityButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0056b3;
  }
`;
export const QuantityValue = styled.span`
  text-align: center;
  display:flex;
  border: none;
  border-top: 1px solid var(--border-color2);
  border-bottom: 1px solid var(--border-color2);
  margin: 0px;
  width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
    align-items: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingLayout = styled.div`
position:fixed;
height:100vh;
width:100vw;
top:0;
left:0;
right:0;
bottom:0;
z-index:100;
background: transparent;
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.37);

.spinnerContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${rotate360} 0.8s linear infinite;
}

`

const plopp = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -10px);
    opacity: 0;
  }
`

export const EmptyCartSVG = styled.div` 
  width: 50vw;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;

  svg {
    max-width: 100%;
    padding: 5rem 3rem;

    #oval,
    #plus,
    #diamond,
    #bubble-rounded {
      animation: ${plopp} 4s ease-out infinite;
    }

    #oval {
      animation-delay: -240ms;
    }
    #plus {
      animation-delay: -480ms;
    }
    #diamond {
      animation-delay: -720ms;
    }
    #bubble-rounded {
      animation-delay: -960ms;
    }
  }

  @media only screen and (min-width: 768px) {
    svg {
      max-width: 60%; 
      padding: 3rem 1rem;
    }
  }



    #bg-line {
      &:nth-child(2) {
        fill-opacity: 0.3;
      }
      &:nth-child(3) {
        fill-opacity: 0.4;
      }
    }

    h3 {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 24px;
  max-width: 80%;
  margin: 1.25rem auto 0 auto;
}

`




