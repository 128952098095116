const firebaseConfig = {
    apiKey: "AIzaSyBkxedYGee09f_O7XEXAD27J730Cv9I4OQ",
    authDomain: "udovibe.firebaseapp.com",
    projectId: "udovibe",
    storageBucket: "udovibe.appspot.com",
    messagingSenderId: "940846129581",
    appId: "1:940846129581:web:95365f9380695f6a5c4124",
    measurementId: "G-8FFWWGC8QB"
  };
  
  export default firebaseConfig;