import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'swiper/css';

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import React, { lazy, Suspense } from 'react';
import { ShopProvider } from './Context/ShopContext';
import { ProductQuickViewProvider } from './Context/ProductQuickView';
import { UserProvider } from './Context/UserContext';
import { OrderProvider } from './Context/OrderContext';
import Blogs from './Components/Blogs/Blogs';

// Import your Home component with lazy loading
const Home = lazy(() => import('./Components/Home/Home'));
const Shop = lazy(() => import('./Components/Shop/Shop'));
const Cart = lazy(()=> import('./Components/Checkout/Cart'));
const Checkout = lazy(()=>import('./Components/Checkout/Checkout'))
const ProductDetail = lazy(() => import('./Components/Shop/ProductDetail'))
const PageNotFound = lazy(() => import('./Components/PageNotFound/PageNotFound'));
const UserAccountDetails = lazy(()=>import('./Components/UserManagement/UserPageView'))
const SignInUPForm = lazy(()=> import('./Components/UserManagement/SignInUPForm'))
const AboutUs = lazy(()=> import('./Components/About Us/AboutUs'))
const ContactUs= lazy(()=> import('./Components/Contact/Contact'))
const Collections = lazy(()=> import('./Components/Collections/Collections'))
const BlockKits = lazy(()=>import('./Components/Collections/BlockKits'))
const ViewAllProducts = lazy(()=>import('./Components/Collections/ViewAllProducts'))

function App() {
  return (
    <>
    <UserProvider>
      <ShopProvider>
        <OrderProvider>
        <ProductQuickViewProvider>
          <Router>
            <Header />

            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                {/* Define your routes with lazy-loaded components */}
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path='/shop/:id/:subCategory' element={<Shop />} />
                <Route path='/productdetails/:id' element={<ProductDetail />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/checkout' element={<Checkout />} />
                <Route path='/userAccountDetails' element={<UserAccountDetails />} />
                <Route path='/sigin' element= {<SignInUPForm/>}/>
                <Route path='/aboutUs' element={<AboutUs/>}/>
                <Route path='/contactUs' element = {<ContactUs/>}/>
                <Route path='/collections' element={<Collections/>}/>
                <Route path='/collections/:id/:subCategory' element={<Collections/>}/>
                <Route path='/blockkits' element= {<BlockKits/>}/>
                <Route path ='/searchAllProducts' element={<ViewAllProducts/>}/>
                <Route path ='/searchAllProducts/:id' element={<ViewAllProducts/>}/>
                <Route path='/blogs' element={<Blogs/>}/>



                {/* Route for 404 Not Found */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>

            <Footer />
          </Router>
        </ProductQuickViewProvider>
        </OrderProvider>
      </ShopProvider>
      </UserProvider>
    </>
  );
}

export default App;
