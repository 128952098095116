import { createContext, useContext, useState } from "react";

const ProductQuickViewContext = createContext({
    display: false,
    data: {}
});

export const useProductQuickViewContext = () => {
    return useContext(ProductQuickViewContext)
}

export const ProductQuickViewProvider = ({ children }) => {
    const [productDetails, setProductDetails] = useState({
        display: false,
        data: {}
    })

    const editQuickViewDetails = (display=true, data = {}) => {
        setProductDetails({
            display,
            data
        })
        return
    }

    const contextValue = {
        productDetails,
        editQuickViewDetails
    }
    return (
        <ProductQuickViewContext.Provider value={contextValue}>
            {children}
        </ProductQuickViewContext.Provider>
    )
}