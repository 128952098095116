import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
`;

const marqueeAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const MarqueeText = styled.div`
  display: inline-block;
  background-color:#F4CE14;
  animation: ${marqueeAnimation} linear infinite;
  animation-duration: 20s; /* Default duration for larger screens */

    @media (max-width: 768px) {
        animation-duration: 20s; /* Adjusted duration for mobile screens (max-width: 768px) */
    }
animation-timing-function: linear;
`;


const Marquee = () => {
    useEffect(() => {
        const marqueeText = '<b>Now get free shipping on orders above ₹300!</b>';
        const marquee = document.querySelector('.marquee');
        marquee.innerHTML = marqueeText + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + marqueeText + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + marqueeText + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + marqueeText + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + marqueeText;
        

        marquee.addEventListener('animationiteration', () => {
            marquee.style.animationPlayState = 'paused';
            setTimeout(() => {
                marquee.style.animationPlayState = 'running';
            }, 0);
        });
    }, []);

    return (
        <MarqueeContainer className="marquee-container">
            <MarqueeText className="marquee">
                {/* Marquee text will be inserted here */}
            </MarqueeText>
        </MarqueeContainer>
    );
};

export default Marquee;
