import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GrFormNext } from 'react-icons/gr';
import { categoriesData, subCategoryData, shopCategories } from '../Shop/Data'
import { useShopContext } from '../../Context/ShopContext';
import QuantityControlBtn from '../Misc/QuantityControlBtn';
import Marquee from '../Misc/Marquee';
import HeaderSearchPosters from '../Misc/HeaderSearchPosters';
import udovibeLogo from '../../assets/logo/UDOVIBE.svg'
import { Rupess_Symbol } from '../Misc/Constants';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';


const Header = () => {
  const [selectedCategory, setSelectedCategory] = useState("Anime");
  const [selectSubCategory, setSelectSubCategory] = useState("Anime")
  const [displayedSubcategories, setDisplayedSubcategories] = useState(['Death Note', 'Naruto', 'One Piece', 'Pokemon', 'Dragon Ball Z', 'Others']);
  const { cartItems, calculateSubTotal, calculateTotal, updateQuantity, removeFromCart } = useShopContext();
  const [quantities, setQuantities] = useState(cartItems.map(item => item.quantity));
  const [showSearchPosters, setShowSearchPosters] = useState('')
  const [openSideBar, setOpenSideBar] = useState('')
  const [offCanvasShop, setOffCanvasShop] = useState(false)
  const [slideShowCart, setSlideShowCart] = useState('')
  const cartSliderRef = useRef(null); 
  const ref = useRef(null)

  const handleDecrement = (id, index) => {
    if (quantities[index] > 1) {
      const quantity = quantities[index];
      updateQuantity(id, quantity - 1);
    }
    else {
      removeFromCart(id)
    }

  };
  useEffect(() => {
    setQuantities(cartItems.map(item => item.quantity));
  }, [cartItems])

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenSideBar('');
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  

  useEffect(()=>{
    function handleClickOutside(event) {
      if (cartSliderRef.current && !cartSliderRef.current.contains(event.target)) {
        setSlideShowCart('');
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  },[cartSliderRef])

  const handleIncrement = (id, index) => {
    const quantity = quantities[index];
    updateQuantity(id, quantity + 1);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setDisplayedSubcategories([]);
  };


  const handleCategoryMouseEnter = (category) => {
    // Set the subcategories to display for the hovered category
    setSelectSubCategory(category || [])
    setDisplayedSubcategories(subCategoryData[category] || []);
  };
  const [offset, setOffset] = useState(0);

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header className="header__section header__section3 header__transparent mb-20 color-scheme-3 ">
      <div className="header__topbar border-bottom ">
          <Marquee />
      </div>
      <div className={`main__header main__header--style3 header__sticky ${offset>45?'sticky':''}`}>
        <div className="container">
          <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
            <div className="offcanvas__header--menu__open " onClick={() => setOpenSideBar('open')}>
              <div
                className="offcanvas__header--menu__open--btn"
                style={{cursor:'pointer'}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon offcanvas__header--menu__open--svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M80 160h352M80 256h352M80 352h352"
                  />
                </svg>
                <span className="visually-hidden">Offcanvas Menu Open</span>
              </div>
            </div>
            <div className="main__logo">
              <h1 className="main__logo--title">
                <Link to='/' className="main__logo--link">
                  <img
                    className="main__logo--img"
                    src={udovibeLogo}
                    alt="udoVibe"
                    width={'70'}
                  />
                </Link>
              </h1>
            </div>
            <div className="header__menu d-none d-lg-block">
              <nav className="header__menu--navigation style3">
                <ul className="d-flex justify-content-center">
                  <li className="header__menu--items style3">
                    <Link to='/' className="header__menu--link style3" >
                      Home
                    </Link>
                  </li>
                  <li className="header__menu--items mega__menu--items style3">
                    <Link to='/shop' className="header__menu--link style3">
                      Posters <span className="menu__plus--icon">+</span>
                    </Link>
                    <ul className="header__mega--menu d-flex">

                      {/* Column Two (categoriesData) */}
                      <li className="header__mega--menu__li">
                        <span className="header__mega--subtitle">Categories</span>
                        <ul className="header__mega--sub__menu">
                          {categoriesData.map((category) => (
                            <li
                              key={category}
                              className="header__mega--sub__menu_li"
                              onMouseEnter={() => handleCategoryMouseEnter(category)}
                            >
                              <Link
                                to={`/shop/${category}/noSelect`}
                                onClick={()=>logEvent(analytics,`categories ${category}`)}
                                className={`header__mega--sub__menu--title flex-spaceBtn ${selectedCategory === category ? 'active' : ''
                                  }`}
                              >
                                <span>{category}</span><span><GrFormNext /></span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>

                      {/* Column Three (subCategoryData) */}
                      {displayedSubcategories.length > 0 && (
                        <li className="header__mega--menu__li">
                          <span className="header__mega--subtitle">Posters</span>
                          <ul className="header__mega--sub__menu">
                            {displayedSubcategories.map((subcategory) => (
                              <li key={subcategory} className="header__mega--sub__menu_li">
                                <Link
                                  to={`/shop/${selectSubCategory}/${subcategory}`}
                                  className="header__mega--sub__menu--title"
                                >
                                  {subcategory}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}

                      {/* Add your other columns here using a similar pattern */}
                    </ul>
                  </li>
                  <li className="header__menu--items style3">
                    <Link className="header__menu--link style3" to='/collections'>
                      Collections <span className="menu__plus--icon">+</span>{" "}
                    </Link>
                    <ul className="header__sub--menu">
                    {categoriesData.map((category) => (
                            <li
                              key={category}
                              className="header__sub--menu__items"
                            >
                              <Link
                                to={`/collections/${category}/noSelect`}
                                className="header__sub--menu__link"
                              >
                                {category}
                              </Link>
                            </li>
                          ))}
                    </ul>
                  </li>
                  <li className="header__menu--items style3">
                    <Link to="/blockkits" className="header__menu--link style3">
                      Block Kits{" "}
                    </Link>
                  </li>
                  <li className="header__menu--items style3">
                    <Link to="/aboutUs" className="header__menu--link style3">
                      About US{" "}
                    </Link>
                  </li>
                  <li className="header__menu--items style3">
                    <Link
                      to="/contactUs" className="header__menu--link style3" >
                      Contact{" "}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header__account">
              <ul className="d-flex">
                <li className="header__account--items  header__account--search__items">
                  <button
                    className="header__account--btn search__open--btn"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    onClick={() => setShowSearchPosters('active')}
                  >
                    <svg
                      className="header__search--button__svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.51"
                      height="23.443"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      />
                    </svg>
                    <span className="visually-hidden">Search</span>
                  </button>
                </li>
                <li className="header__account--items">
                  <Link className="header__account--btn" to='/userAccountDetails'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.51"
                      height="23.443"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                      />
                      <path
                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                      />
                    </svg>
                    <span className="visually-hidden">My Account</span>
                  </Link>
                </li>
                <li className="header__account--items" onClick={() => setSlideShowCart('active')}>
                  <div
                    className="header__account--btn minicart__open--btn"
                   style={{cursor:'pointer'}}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.897"
                      height="21.565"
                      viewBox="0 0 18.897 21.565"
                    >
                      <path
                        d="M16.84,8.082V6.091a4.725,4.725,0,1,0-9.449,0v4.725a.675.675,0,0,0,1.35,0V9.432h5.4V8.082h-5.4V6.091a3.375,3.375,0,0,1,6.75,0v4.691a.675.675,0,1,0,1.35,0V9.433h3.374V21.581H4.017V9.432H6.041V8.082H2.667V21.641a1.289,1.289,0,0,0,1.289,1.29h16.32a1.289,1.289,0,0,0,1.289-1.29V8.082Z"
                        transform="translate(-2.667 -1.366)"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="items__count">{cartItems.length}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Start Offcanvas header menu */}
      <div ref={ref} className={`offcanvas__header ${openSideBar} color-scheme-3`} tabIndex={-1}>
        <div className="offcanvas__inner">
          <div className="offcanvas__logo">
            <Link to='/' className="offcanvas__logo_link">
              <img src={udovibeLogo} alt="udovibe Logo" width='60px' />
            </Link>
            <button
              className="offcanvas__close--btn"
              aria-label="offcanvas close btn"
              onClick={() => setOpenSideBar('')}
            >
              close
            </button>
          </div>
          <nav className="offcanvas__menu">
            <ul className="offcanvas__menu_ul">
              <li className="offcanvas__menu_li">
                <Link to='/' className="offcanvas__menu_item" >
                  Home
                </Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to='/shop'>
                  Posters
                </Link>
                <button className={`offcanvas__sub_menu_toggle ${offCanvasShop ? 'active' : ''}`} onClick={() => setOffCanvasShop(!offCanvasShop)}></button>
                <ul className="offcanvas__sub_menu" style={{ display: `${offCanvasShop ? 'block' : 'none'}` }}>
                  {categoriesData.map((category) => (
                    <li className="offcanvas__sub_menu_li" key={category}>
                      <Link
                        to={`/shop/${category}/noSelect`}
                        className="offcanvas__sub_menu_item">
                        {category}
                      </Link>
                    </li>
                  ))}

                </ul>
              </li>

              {/* <li className="header__menu--items mega__menu--items style3">
  <a className="header__menu--link style3" href="shop.html">
    Shop <span className="menu__plus--icon">+</span>
  </a>
  <ul className="header__mega--menu d-flex">
    <li className="header__mega--menu__li">
      <span className="header__mega--subtitle">Column One</span>
      <ul className="header__mega--sub__menu">
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="shop.html">
            Shop Left Sidebar
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="shop-right-sidebar.html"
          >
            Shop Right Sidebar
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="shop-grid.html">
            Shop Grid
          </a>
        </li>
        <li className="header__mega--sub__menuF_li">
          <a
            className="header__mega--sub__menu--title"
            href="shop-grid-list.html"
          >
            Shop Grid List
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="shop-list.html">
            Shop List
          </a>
        </li>
      </ul>
    </li>
    <li className="header__mega--menu__li">
      <span className="header__mega--subtitle">Column Two</span>
      <ul className="header__mega--sub__menu">
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="product-details.html"
          >
            Standard Product
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="product-variable.html"
          >
            Video Product
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="product-variable.html"
          >
            Variable Product
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="product-left-sidebar.html"
          >
            Product Left Sidebar
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="product-gallery.html"
          >
            Product Gallery
          </a>
        </li>
      </ul>
    </li>
    <li className="header__mega--menu__li">
      <span className="header__mega--subtitle">Column Three</span>
      <ul className="header__mega--sub__menu">
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="my-account.html">
            My Account
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a
            className="header__mega--sub__menu--title"
            href="my-account-2.html"
          >
            My Account 2
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="404.html">
            404 Page
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="login.html">
            Login Page
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="faq.html">
            Faq Page
          </a>
        </li>
      </ul>
    </li>
    <li className="header__mega--menu__li">
      <span className="header__mega--subtitle">Column Four</span>
      <ul className="header__mega--sub__menu">
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="compare.html">
            Compare Pages
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="cart.html">
            Cart Page
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="checkout.html">
            Checkout page
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="wishlist.html">
            Wishlist Page
          </a>
        </li>
        <li className="header__mega--sub__menu_li">
          <a className="header__mega--sub__menu--title" href="404.html">
            Error Page
          </a>
        </li>
      </ul>
    </li>
  </ul>
</li> */}












              <li className="offcanvas__menu_li">
                <Link to="/collections" className="offcanvas__menu_item" >
                  Collections
                </Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link to="/blockkits" className="offcanvas__menu_item" >
                  Block Kits
                </Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link to="/aboutUs" className="offcanvas__menu_item" >
                  About
                </Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link
                  to="/contactUs" className="offcanvas__menu_item">
                  Contact
                </Link>
              </li>
            </ul>
            <div className="offcanvas__account--items">
              <Link
                className="offcanvas__account--items__btn d-flex align-items-center"
                to="/userAccountDetails"
              >
                <span className="offcanvas__account--items__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.51"
                    height="19.443"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={32}
                    />
                    <path
                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit={10}
                      strokeWidth={32}
                    />
                  </svg>
                </span>
                <span className="offcanvas__account--items__label">
                  Login / Register
                </span>
              </Link>
            </div>
          </nav>
        </div>
      </div>
      {/* End Offcanvas header menu */}
      {/* Start Offcanvas stikcy toolbar */}
      <div className="offcanvas__stikcy--toolbar color-scheme-3" tabIndex={-1}>
        <ul className="d-flex justify-content-between">
          <li className="offcanvas__stikcy--toolbar__list">
            <Link to='/' className="offcanvas__stikcy--toolbar__btn">
              <span className="offcanvas__stikcy--toolbar__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  width="21.51"
                  height="21.443"
                  viewBox="0 0 22 17"
                >
                  <path
                    fill="currentColor"
                    d="M20.9141 7.93359c.1406.11719.2109.26953.2109.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.21094-.4219.21094-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.2109.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.2109-.2109-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.21094.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.21094-.2109-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.21094l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.21094-.45703L9.73438.832031C10.1094.527344 10.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.101559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.10547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Home</span>
            </Link>
          </li>
          <li className="offcanvas__stikcy--toolbar__list">
            <Link to='/shop' className="offcanvas__stikcy--toolbar__btn" >
              <span className="offcanvas__stikcy--toolbar__icon">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.51"
                  height="17.443"
                  viewBox="0 0 448 512"
                >
                  <path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z" />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Shop</span>
            </Link>
          </li>
          <li className="offcanvas__stikcy--toolbar__list " onClick={() => setShowSearchPosters('active')}>
            <a
              className="offcanvas__stikcy--toolbar__btn search__open--btn"
            >
              <span className="offcanvas__stikcy--toolbar__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.51"
                  height="20.443"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M338.29 338.29L448 448"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Search</span>
            </a>
          </li>
          <li className="offcanvas__stikcy--toolbar__list" onClick={() => setSlideShowCart('active')}>
            <div
              className="offcanvas__stikcy--toolbar__btn minicart__open--btn"
              style={{cursor:'pointer'}}
            >
              <span className="offcanvas__stikcy--toolbar__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.51"
                  height="15.443"
                  viewBox="0 0 18.51 15.443"
                >
                  <path
                    d="M79.963,138.379l-13.358,0-.56-1.927a.871.871,0,0,0-.6-.592l-1.961-.529a.91.91,0,0,0-.226-.03.864.864,0,0,0-.226,1.7l1.491.4,3.026,10.919a1.277,1.277,0,1,0,1.844,1.144.358.358,0,0,0,0-.049h6.163c0,.017,0,.034,0,.049a1.277,1.277,0,1,0,1.434-1.267c-1.531-.247-7.783-.55-7.783-.55l-.205-.8h7.8a.9.9,0,0,0,.863-.651l1.688-5.943h.62a.936.936,0,1,0,0-1.872Zm-9.934,6.474H68.568c-.04,0-.1.008-.125-.085-.034-.118-.082-.283-.082-.283l-1.146-4.037a.061.061,0,0,1,.011-.057.064.064,0,0,1,.053-.025h1.777a.064.064,0,0,1,.063.051l.969,4.34,0,.013a.058.058,0,0,1,0,.019A.063.063,0,0,1,70.03,144.853Zm3.731-4.41-.789,4.359a.066.066,0,0,1-.063.051h-1.1a.064.064,0,0,1-.063-.051l-.789-4.357a.064.064,0,0,1,.013-.055.07.07,0,0,1,.051-.025H73.7a.06.06,0,0,1,.051.025A.064.064,0,0,1,73.76,140.443Zm3.737,0L76.26,144.8a.068.068,0,0,1-.063.049H74.684a.063.063,0,0,1-.051-.025.064.064,0,0,1-.013-.055l.973-4.357a.066.066,0,0,1,.063-.051h1.777a.071.071,0,0,1,.053.025A.076.076,0,0,1,77.5,140.448Z"
                    transform="translate(-62.393 -135.3)"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Cart</span>
              <span className="items__count">{cartItems.length}</span>
            </div>
          </li>
        </ul>
      </div>
      {/* End Offcanvas stikcy toolbar */}
      {/* Start offCanvas minicart */}
      <div ref={cartSliderRef} className={`offCanvas__minicart color-scheme-3 ${slideShowCart}`} tabIndex={-1}>
        <div className="minicart__header ">
          <div className="minicart__header--top d-flex justify-content-between align-items-center">
            <h3 className="minicart__title"> Shopping Cart</h3>
            <button
              className="minicart__close--btn"
              aria-label="minicart close btn"
              onClick={() => setSlideShowCart('')}
            >
              <svg
                className="minicart__close--icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={32}
                  d="M368 368L144 144M368 144L144 368"
                />
              </svg>
            </button>
          </div>
          <p className="minicart__header--desc">
            Posters for Every Passion, Style, and Mood
          </p>
        </div>
        {/* Saubhagya Coding */}

        <div className="minicart__product">
          {cartItems.map((item, index) => (
            <div className="minicart__product--items d-flex" key={item.id}>
              <div className="minicart__thumbnail">
                <Link to={`/productdetails/:${item.id}`}>
                  <img src={item.picUrl} alt={item.Caption} />
                </Link>
              </div>
              <div className="minicart__text">
                <h4 className="minicart__subtitle">
                <Link to={`/productdetails/:${item.id}`}>{item.Caption}</Link>
                </h4>
                <span className="color__variant">
                  <b>Size & Quality:</b> {item.size}, {(item.quality).toUpperCase()}
                </span>
                <div className="minicart__price">
                  <span className="current__price">&#8377;{item.price}</span>
                  <span className="old__price">&#8377;{(item.price * 1) + 40}</span>
                </div>
                <div className="minicart__text--footer d-flex align-items-center">
                  <div className="quantity__box minicart__quantity">
                    <QuantityControlBtn
                      quantity={quantities[index]}
                      handleDecrement={() => handleDecrement(item.id, index)}
                      handleIncrement={() => handleIncrement(item.id, index)}
                    />
                  </div>
                  <button
                    className="minicart__product--remove"
                    aria-label="minicart remove btn"
                    onClick={() => removeFromCart(item.id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="minicart__amount">
            <div className="minicart__amount_list d-flex justify-content-between">
              <span>Sub Total:</span>
              <span>
                <b>&#8377;{calculateSubTotal(cartItems)}</b>
              </span>
            </div>
            
           {calculateSubTotal(cartItems)>0? <div className="minicart__amount_list d-flex justify-content-between">
              <span>Convenience Fee:</span>
              <span>
                <b>&#8377;{50}</b>
              </span>
            </div>:''}

            <div className="minicart__amount_list d-flex justify-content-between">
              <span>Total:</span>
              <span>
                <b>&#8377;{calculateTotal(cartItems)}</b>
              </span>
            </div>
          </div>
        </div>

        {/* Saubhagya Codings ends */}
        <div className="minicart__conditions text-center">
         
          <label className="minicart__conditions--label" htmlFor="accept">
            {`Get free delivery on order above ${Rupess_Symbol}300!`}
            
          </label>
        </div>
        <div className="minicart__button d-flex justify-content-center">
          <Link
            className="primary__btn minicart__button--link btn__style3"
            to='/cart'
          >
            View cart
          </Link>
          <Link
            className="primary__btn minicart__button--link btn__style3"
            to="/checkout"
          >
            Checkout
          </Link>
        </div>
      </div>
      {/* End offCanvas minicart */}
      {/* Start serch box area */}
      <HeaderSearchPosters showSearchPosters={showSearchPosters} setShowSearchPosters={setShowSearchPosters}  />
      {/* End serch box area */}
    </header>


  )
}

export default Header