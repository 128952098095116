import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const HeaderSearchPosters = ({ showSearchPosters, setShowSearchPosters }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showSearchPosters !== "" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchPosters]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchPosters("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/searchAllProducts/${inputValue}`);
    setInputValue("");
    setShowSearchPosters("");
  };
  return (
    <div
      ref={searchRef}
      className={`predictive__search--box color-scheme-3 ${showSearchPosters}`}
      tabIndex={-1}
    >
      <div className="predictive__search--box__inner">
        <h2 className="predictive__search--title">Search Posters</h2>
        <form className="predictive__search--form" onSubmit={handleSearch}>
          <label>
            <input
              className="predictive__search--input"
              ref={inputRef}
              placeholder="Search Here"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </label>
          <button
            className="predictive__search--button"
            aria-label="search button"
            type="submit"
          >
            <svg
              className="header__search--button__svg"
              xmlns="http://www.w3.org/2000/svg"
              width="30.51"
              height="25.443"
              viewBox="0 0 512 512"
            >
              <path
                d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit={10}
                strokeWidth={32}
              />
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M338.29 338.29L448 448"
              />
            </svg>
          </button>
        </form>
      </div>
      <button
        className="predictive__search--close__btn"
        aria-label="search close btn"
        onClick={() => setShowSearchPosters("")}
      >
        <svg
          className="predictive__search--close__icon"
          xmlns="http://www.w3.org/2000/svg"
          width="40.51"
          height="30.443"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={32}
            d="M368 368L144 144M368 144L144 368"
          />
        </svg>
      </button>
    </div>
  );
};

export default HeaderSearchPosters;
