import React, { createContext, useContext, useState } from 'react';

// Create the OrderContext
const OrderContext = createContext();

// Create a custom hook to access the context values
export function useOrderContext() {
    return useContext(OrderContext);
}

// Create the OrderProvider component
export function OrderProvider({ children }) {
    const [orders, setOrders] = useState([]); // Assuming orders is an array to store multiple orders

    // Add a new order to the orders state
    const addOrder = (order) => {
        setOrders([...orders, order]);
    };

    return (
        <OrderContext.Provider value={{ orders, addOrder }}>
            {children}
        </OrderContext.Provider>
    );
}
