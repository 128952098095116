// A4, A5 - single poster  - regular
export const SINGLE_A5_Regular = 50;
export const SINGLE_A4_REGULAR = 10;

// A4, A5 - single poster  - premium
export const SINGLE_A5_PREMIUM = 50;
export const SINGLE_A4_PREMIUM = 10;

// deleivery chargre
export const DELIVERY_CHARGE = 150;

// poster type
export const PREMIUM_POSTER = 'Premium'
export const REGULAR_POSTER = 'Regular'

//poster size
export const a4_size_poster = 'A4'
export const a5_size_poster = 'A5'


//urls
export const facebookUrl = 'https://www.facebook.com/profile.php?id=61550691327411'
export const instagramUrl = 'https://www.instagram.com/udo.vibe/'
export const youtubeUrl = 'https://www.youtube.com/@uDovibe'
export const twitterUrl= 'https://twitter.com/udovibe?t=pjU8dFBTB-Hpnm89ChEMfg&s=09'

export const Rupess_Symbol = '₹'

export function formatDate(timestamp) {
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
  const day = date.getDate();
  return `${month} ${day}, ${year}`;
}

export function calculateDiscount(price = 0) {
  let currPrice = price * 1;
  if (typeof currPrice === 'number') {
    return Math.round(currPrice * 0.9);
  }
  return 0
}

export const priceCalculator = (set, size = a5_size_poster, quality = REGULAR_POSTER) => {
  let currSet = set * 1
  // set=1
  if (currSet === 1 && size === a5_size_poster && quality === REGULAR_POSTER) {
    return 50;
  }
  if (currSet === 1 && size === a4_size_poster && quality === REGULAR_POSTER) {
    return 60;
  }
  if (currSet === 1 && size === a5_size_poster && quality === PREMIUM_POSTER) {
    return 69;
  }
  if (currSet === 1 && size === a4_size_poster && quality === PREMIUM_POSTER) {
    return 129;
  }

  //currSet =9
  if (currSet === 9 && size === a5_size_poster && quality === REGULAR_POSTER) {
    return 499;
  }
  if (currSet === 9 && size === a4_size_poster && quality === REGULAR_POSTER) {
    return 599;
  }
  if (currSet === 9 && size === a5_size_poster && quality === PREMIUM_POSTER) {
    return 699;
  }
  if (currSet === 9 && size === a4_size_poster && quality === PREMIUM_POSTER) {
    return 899;
  }

  //currSet =6
  if (currSet === 6 && size === a5_size_poster && quality === REGULAR_POSTER) {
    return 300;
  }
  if (currSet === 6 && size === a4_size_poster && quality === REGULAR_POSTER) {
    return 360;
  }
  if (currSet === 6 && size === a5_size_poster && quality === PREMIUM_POSTER) {
    return 359;
  }
  if (currSet === 6 && size === a4_size_poster && quality === PREMIUM_POSTER) {
    return 559;
  }

  //currSet =10
  if (currSet === 10 && size === a5_size_poster && quality === REGULAR_POSTER) {
    return 450;
  }
  if (currSet === 10 && size === a4_size_poster && quality === REGULAR_POSTER) {
    return 550;
  }
  if (currSet === 10 && size === a5_size_poster && quality === PREMIUM_POSTER) {
    return 499;
  }
  if (currSet === 10 && size === a4_size_poster && quality === PREMIUM_POSTER) {
    return 809;
  }
}

export const couponCodes = {
  vibe10: 10,
  saubhagya15: 15
}
export const calculateCopounDiscount=(price, discountPercentage) => {
  return (price * discountPercentage) / 100;
};
export const totalCharges = (price = 0, code = '') => {
  if (price) {
    let total = 0;
    let discountPrice = 0;
    let convienceFee = 50;
    let deliveryCharge = 0;

    if (price >= 500 && couponCodes[code]) {
      discountPrice = calculateCopounDiscount(price, couponCodes[code]);
      total = price - discountPrice + convienceFee;
    } else if (price > 300) {
      deliveryCharge = 0;
      total = price + convienceFee;
    } else {
      deliveryCharge = 150;
      total = price + convienceFee + deliveryCharge;
    }

    return { price, discountPrice, convienceFee, deliveryCharge, total };
  } else {
    return { price: 0, discountPrice: 0, convienceFee: 0, deliveryCharge: 0, total: 0 };
  }
};
export const validEmailDomain = [
  "aol.com",
  "att.net",
  "comcast.net",
  "facebook.com",
  "gmail.com",
  "gmx.com",
  "googlemail.com",
  "google.com",
  "hotmail.com",
  "hotmail.co.uk",
  "mac.com",
  "me.com",
  "mail.com",
  "msn.com",
  "live.com",
  "sbcglobal.net",
  "verizon.net",
  "yahoo.com",
  "yahoo.co.uk",
  "email.com",
  "fastmail.fm",
  "games.com",
  "gmx.net",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "lavabit.com",
  "love.com",
  "outlook.com",
  "pobox.com",
  "protonmail.ch",
  "protonmail.com",
  "tutanota.de",
  "tutanota.com",
  "tutamail.com",
  "tuta.io",
  "keemail.me",
  "rocketmail.com",
  "safe-mail.net",
  "wow.com",
  "ygm.com",
  "ymail.com",
  "zoho.com",
  "yandex.com",
  "bellsouth.net",
  "charter.net",
  "cox.net",
  "earthlink.net",
  "juno.com",
  "btinternet.com",
  "virginmedia.com",
  "blueyonder.co.uk",
  "freeserve.co.uk",
  "live.co.uk",
  "ntlworld.com",
  "o2.co.uk",
  "orange.net",
  "sky.com",
  "talktalk.co.uk",
  "tiscali.co.uk",
  "virgin.net",
  "wanadoo.co.uk",
  "bt.com",
  "sina.com",
  "sina.cn",
  "qq.com",
  "naver.com",
  "hanmail.net",
  "daum.net",
  "nate.com",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.com.sg",
  "yahoo.com.ph",
  "163.com",
  "yeah.net",
  "126.com",
  "21cn.com",
  "aliyun.com",
  "foxmail.com",
  "hotmail.fr",
  "live.fr",
  "laposte.net",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "gmx.fr",
  "sfr.fr",
  "neuf.fr",
  "free.fr",
  "gmx.de",
  "hotmail.de",
  "live.de",
  "online.de",
  "t-online.de",
  "web.de",
  "yahoo.de",
  "libero.it",
  "virgilio.it",
  "hotmail.it",
  "aol.it",
  "tiscali.it",
  "alice.it",
  "live.it",
  "yahoo.it",
  "email.it",
  "tin.it",
  "poste.it",
  "teletu.it",
  "mail.ru",
  "rambler.ru",
  "yandex.ru",
  "ya.ru",
  "list.ru",
  "hotmail.be",
  "live.be",
  "skynet.be",
  "voo.be",
  "tvcablenet.be",
  "telenet.be",
  "hotmail.com.ar",
  "live.com.ar",
  "yahoo.com.ar",
  "fibertel.com.ar",
  "speedy.com.ar",
  "arnet.com.ar",
  "yahoo.com.mx",
  "live.com.mx",
  "hotmail.es",
  "hotmail.com.mx",
  "prodigy.net.mx",
  "yahoo.ca",
  "hotmail.ca",
  "bell.net",
  "shaw.ca",
  "sympatico.ca",
  "rogers.com",
  "yahoo.com.br",
  "hotmail.com.br",
  "outlook.com.br",
  "uol.com.br",
  "bol.com.br",
  "terra.com.br",
  "ig.com.br",
  "itelefonica.com.br",
  "r7.com",
  "zipmail.com.br",
  "globo.com",
  "globomail.com",
  "oi.com.br"
]