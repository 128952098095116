import React from 'react'
import { QuantityValue } from './styles';

const QuantityControlBtn = ({ quantity, handleDecrement, handleIncrement }) => {
  return (
    <div className="quantity__box">
      <button
        type="button"
        className="quantity__value quickview__value--quantity decrease"
        aria-label="quantity value"
        onClick={handleDecrement}
      >
        -
      </button>
      <QuantityValue>
        {quantity}
      </QuantityValue>
      <button
        type="button"
        className="quantity__value quickview__value--quantity increase"
        aria-label="quantity value"
        onClick={handleIncrement}
      >
        +
      </button>
    </div>
  );
};

export default QuantityControlBtn