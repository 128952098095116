import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { addDoc, doc, setDoc } from 'firebase/firestore';

const UserContext = createContext();
export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const { email, uid, emailVerified } = authUser;
        updateUserState(email, uid, emailVerified, true);
        updateUserDetailsInFirestore(email, uid, emailVerified)
      } else {
        updateUserState(null, null, false, false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateUserState = (email, uid, emailVerified, isLoggedIn) => {
    setUser({ email, uid, emailVerified, isLoggedIn });
  };

  const updateUserDetailsInFirestore = async (email, uid, emailVerified) => {
    
    const userDetailsRef = doc(db, 'userDetails', uid);
    const userDetailsData = {
      name: user?.name|| 'new user', 
      email,
      verifiedEmail: emailVerified,
      lastLogin: new Date().toString(),
    };

    setDoc(userDetailsRef, userDetailsData, { merge: true })
      .then(() => {
        console.log('User details updated in Firestore');
      })
      .catch((error) => {
        console.error('Error updating user details:', error);
      });
  };

  const login = async (loginEmail, loginPassword) => {
    try {
      const loginUser = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      const { email, uid, emailVerified } = loginUser.user;
      updateUserState(email, uid, emailVerified, true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const { user } = result;
        const { email, uid, emailVerified } = user;
        updateUserState(email, uid, emailVerified, true);
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, if needed
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const { user } = result;
        const { email, uid, emailVerified } = user;
        updateUserState(email, uid, emailVerified, true);
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, if needed
      });
  };

  const logout = async () => {
    try {
      await signOut(auth);
      updateUserState(null, null, false, false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <UserContext.Provider value={{ user, login, logout, loading, signInWithGoogle, signInWithFacebook }}>
      {children}
    </UserContext.Provider>
  );
}