import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { useCartDetailsUpdater } from '../Components/Hooks/useUserDetails';
import { useUser } from './UserContext';
import { REGULAR_POSTER, a5_size_poster } from '../Components/Misc/Constants';

const CART_ITEMS_STORAGE_KEY = 'cartItems';

const ShopContext = createContext();

export const useShopContext = () => {
  return useContext(ShopContext);
};

export const ShopProvider = ({ children }) => {
  const postersCollectionRef = collection(db, "categories");


  const updateCartDetails = useCartDetailsUpdater();

  const [itemProduct, setItemProduct] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const { user } = useUser();



  const getPosterList = async () => {
    try {
      const data = await getDocs(postersCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setItemProduct(filteredData);
    } catch (err) {
      console.error('Error fetching poster list:', err);
      console.log('debug 4', err);
    }
  };
  const storeCartItemsInLocalStorage = (items) => {
    localStorage.setItem(CART_ITEMS_STORAGE_KEY, JSON.stringify(items));
  };

  // Function to retrieve cart items from local storage
  const getCartItemsFromLocalStorage = () => {
    const storedItems = localStorage.getItem(CART_ITEMS_STORAGE_KEY);
    return storedItems ? JSON.parse(storedItems) : [];
  };


  useEffect(() => {
    getPosterList();
    const storedCartItems = getCartItemsFromLocalStorage();
    setCartItems(prevCartItems => {
      return [...prevCartItems, ...storedCartItems];
    });
  }, []);
  
  useEffect(() => {
    storeCartItemsInLocalStorage(cartItems);
    const updateFirebaseCart = async () => {
      if (user && user.isLoggedIn) {
        await updateCartDetails(user.uid, cartItems);
      }
    };
  
    updateFirebaseCart();
  }, [cartItems]);




  const addToCart = async (product, productSize = a5_size_poster, productQuality = REGULAR_POSTER, productPrice = 50) => {
    const existingItem = cartItems.find(item => item.id === product.id);
    if (existingItem) {
      const updatedCart = cartItems.map(item =>{
        if(item.id === product.id)
        {
          if(item.price===productPrice)
          {
            return { ...item, quantity: item.quantity + 1, price: productPrice }
          }
          return { ...item, quantity: item.quantity + 1, price: productPrice };
        }
        else return item
       
      }
      );
      setCartItems(updatedCart);
      if (user && user.isLoggedIn) {
        await updateCartDetails(user.uid, cartItems)
      }
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1, price: productPrice, size: productSize, quality: productQuality }]);
      if (user && user.isLoggedIn) {
       await updateCartDetails(user.uid, cartItems)
      }
    }
  };

  const itemTypeAndPrice = async(productId, productSize = a5_size_poster, productQuality = REGULAR_POSTER, productPrice = '50') => {
    const typeAndPrice = cartItems.map(item =>
      item.id === productId ? { ...item, price: productPrice, size: productSize, quality: productQuality } : item
    );
    setCartItems(typeAndPrice)
    if (user && user.isLoggedIn) {
     await updateCartDetails(user.uid, cartItems)
    }
    

  }
  const removeFromCart = async(productId) => {
    itemTypeAndPrice(productId)
    const updatedCart = cartItems.filter(item => item.id !== productId);
    setCartItems(updatedCart);
    if (user && user.isLoggedIn) {
     await updateCartDetails(user.uid, cartItems)
    }
    
  };

  const clearCart = async() => {
    setCartItems([])
    if (user && user.isLoggedIn) {
     await updateCartDetails(user.uid, cartItems)
    }
    
  }
  const updateQuantity = async(productId, newQuantity,productSize = a5_size_poster, productQuality = REGULAR_POSTER, productPrice = 50) => {
    itemTypeAndPrice(productId)
    const updatedCart = cartItems.map(item =>
      item.id === productId ? { ...item, quantity: newQuantity } : item
    );
    setCartItems(updatedCart);
    if (user && user.isLoggedIn) {
     await updateCartDetails(user.uid, cartItems)
    }
    
  };

  // Helper function to calculate sub-total
  const calculateSubTotal = (cartItems) => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // Helper function to calculate total (you can customize this as needed)
  const calculateTotal = (cartItems,convienceFee=50) => {
    // For now, just returning the sub-total as an example
    if (calculateSubTotal(cartItems)>0)
    {
      return calculateSubTotal(cartItems)+convienceFee
    };
    return calculateSubTotal(cartItems)
  };


  const contextValue = {
    itemProduct,
    cartItems,
    addToCart,
    removeFromCart,
    updateQuantity,
    itemTypeAndPrice,
    calculateSubTotal,
    calculateTotal,
    clearCart
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {children}
    </ShopContext.Provider>
  );
};