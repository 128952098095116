import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./firebaseConfig";
import {getAuth} from "firebase/auth"


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app)
const analytics = getAnalytics(app);


export { db, auth, analytics };