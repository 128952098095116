import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

export function useCartDetailsUpdater() {
  const updateCartDetails = async (uid, cartItems) => {
    try {
      const userDetailsRef = doc(db, 'userDetails', uid);

      await updateDoc(userDetailsRef, {
        cartDetails: cartItems,
      });

      console.log('Cart details updated in Firestore', cartItems);
    } catch (error) {
      console.error('Error updating cart details:', error);
    }
  };

  return updateCartDetails;
}
